import React from "react"
import styled from "styled-components"
import SnowRun from "../../content/assets/snowrun.png"
import MainHeader from "../components/header-components/mainHeader"
import Layout from "../components/layout"
import { colors } from "../components/colors/colors"

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
  url("${SnowRun}");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
`

const InternalContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 40px auto;
`
const TextHolder = styled.div`
  width: 80%;
  color: ${colors.white};
  margin: 0 auto;
  > p {
    font-size: 18px;
  }
`

const ThankYouPage = () => {
  return (
    <Layout>
      <Container>
        <MainHeader />
        <InternalContainer>
          <TextHolder>
            <p>
              Thanks for reaching out. We'll get back to you as soon as we can.
            </p>
          </TextHolder>
        </InternalContainer>
      </Container>
    </Layout>
  )
}

export default ThankYouPage
